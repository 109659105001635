<template>
    <v-container fluid>
        <v-card v-if="item && connector">
            <v-toolbar class="mb-2" color="primary" dark flat>
                <v-toolbar-title>
                    Charging Station {{ item.uniqueId }} - Connector {{ connector.connectorId }}
                </v-toolbar-title>
            </v-toolbar>
            <loading v-if="initializeLoading"></loading>
            <div class="red--text text-center" v-else-if="initializeError">
                {{ initializeError }}
            </div>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Field</th>
                            <th class="text-left">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Fee per charge</td>
                            <td>
                                <span class="font-weight-bold">{{ item.tenant.setting.chargingSessionPointPerKwh |
            currency }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Session</td>
                            <td>
                                <v-chip color="red" small text-color="white"> Unpaid </v-chip>
                            </td>
                        </tr>
                        <tr>
                            <td>Time</td>
                            <td>
                                {{ moment().format("YYYY-MM-DD HH:mm:ss") }}
                            </td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>
                                <span class="font-weight-bold" style="font-size: 28px">{{
            item.tenant.setting.chargingSessionPointPerKwh
            | currency }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Balance</td>
                            <td>
                                <span class="font-weight-bold" style="font-size: 28px">{{ point | currency }}</span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <div class="py-4 px-10">
                <x-btn v-if="paymentAmount > 0 && addPointBtnOptions" small block color="primary" :x-options="addPointBtnOptions"
                    :x-context="self"></x-btn>
                <x-btn :x-options="startChargingBtnOptions" :x-context="self"
                v-if="!(paymentAmount > 0 && addPointBtnOptions) && (point >= 0)"></x-btn>
            </div>
        </v-card>
        <loading v-else-if="initializeLoading"></loading>
        <div class="red--text text-center" v-else-if="initializeError">
            {{ initializeError }}
        </div>
        <div class="text-center" v-else>Charging Station or Connector not found</div>
    </v-container>
</template>

<script>
import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import userManager from "@/services/userManager";
import moment from "moment";
import Loading from "@/components/Loading.vue";

import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import PaymentMethodInputVue from "./settings/partials/payment-transactions/PaymentMethodInput.vue";
import PointInputVue from "./settings/partials/payment-transactions/PointInput.vue";

export default Vue.extend({
    components: { Loading },
    data() {
        return {
            tenantSetting: null,
            addPointBtnOptions: null,
            self: this,
            moment,
            startChargingBtnOptions: {
                attrs: {
                    color: "primary",
                    small: true,
                    block: true,
                },
                content: {
                    text: "Start Charging",
                },
                target: {
                    tooltip: {
                        content: {
                            text: "Start Charging",
                        },
                    },
                    confirm: {
                        content: {
                            content: {
                                html: "Are you sure you want to start charging?",
                            },
                        },
                        on: {
                            async xYes({ self }) {
                                const yesBtn = self.yesBtnContainer.value;
                                const btn = self.context();
                                const { $router, item, connector } = btn.context();
                                yesBtn.loading.value = true;
                                const result = await coreApiClient.call("chargingStations", "startCharging", {
                                    id: item.id,
                                    connectorId: connector.connectorId,
                                });
                                yesBtn.loading.value = false;
                                if (result) {
                                    self.hide();
                                    $router.push(`/app/charging_details/${item._id}/${connector.connectorId}`);
                                }
                            },
                        },
                    },
                },
            },
            point: 0,
            initializeLoading: false,
            initializeError: null,
            item: null,
            connector: null,
            paymentAmount: 0
        };
    },
    methods: {
        makeAddPointBtnOptions(point, sessionIdPayment) {
            const amountPoint = Math.round(point);
            return {
                attrs: {
                    color: "primary",
                    dark: false,
                    small: true,
                    class: "ml-4",
                    height: "30px",
                },
                content: {
                    text: "Payment Last Session",
                },
                target: {
                    dialog: {
                        handlers: {
                            initialize() {
                                this.options.content.buttons.save.states.enabled = new DataContainer(false);
                            },
                        },
                        attrs: {
                            width: "700px",
                        },
                        content: {
                            toolbar: {
                                title: "Payment Last Session",
                            },
                            content: {
                                type: "XForm",
                                makeAttrs(attrs, dialog) {
                                    attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                                    attrs.xContext = dialog;
                                    attrs.xData = new DataContainer({});
                                    return attrs;
                                },
                                attrs: {
                                    xOptions: {
                                        content: {
                                            sections: {
                                                default: {
                                                    fields: {
                                                        point: {
                                                            type: PointInputVue,
                                                            attrs: {
                                                                label: "Amount",
                                                                hideLabel: true,
                                                                required: true,
                                                                items: [amountPoint],
                                                                tenantSetting: this.tenantSetting,
                                                            },
                                                            ext: {
                                                                defaultValue: amountPoint,
                                                            },
                                                        },
                                                        paymentMethod: {
                                                            type: PaymentMethodInputVue,
                                                            attrs: {
                                                                label: "Payment Method",
                                                                hideLabel: true,
                                                                required: true,
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            buttons: {
                                cancel: {
                                    ext: {
                                        hidden: true,
                                    },
                                },
                                save: {
                                    attrs: {
                                        block: true,
                                        text: false,
                                    },
                                    content: {
                                        text: "Pay",
                                    },
                                    states: {},
                                    on: {
                                        async xClick({ self }) {
                                            const dialog = self.context();
                                            // const { tenantSetting } = dialog.context().context();
                                            const form = dialog.contentContainer.value;
                                            const data = form.getData();
                                            let apiName = "";
                                            switch (data.paymentMethod.type) {
                                                case "vnpay":
                                                    apiName = "integrationVnpay";
                                                    break;
                                                case "momo":
                                                    apiName = "integrationMomo";
                                                    break;
                                                default:
                                                    apiName = "integrationVnpay";
                                                    break;
                                            }

                                            const amount = data.point;

                                            self.loading.value = true;
                                            const result = await coreApiClient.call(apiName, "createPaymentUrl", {
                                                amount,
                                                id: data.paymentMethod.id,
                                                device: "web",
                                                sessionId : sessionIdPayment
                                            });
                                            self.loading.value = false;
                                            if (result) {
                                                location.href = result;
                                            }
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            };
        },
        async initializeGetChargingStations() {
            const id = this.$route.params.id;
            const connectorId = this.$route.params.connectorId;

            this.initializeLoading = true;
            await this.getLastChargingUnPaid();
            try {
                const item = await coreApiClient.call("chargingStations", "findById", {
                    id,
                });
                if (item) {
                    this.item = item;
                }
                if (this.item) {
                    const connector =
                        this.item.details &&
                        this.item.details.connectors &&
                        this.item.details.connectors.find((c) => +c.connectorId === +connectorId);
                    if (connector) {
                        this.connector = connector;
                    }
                }

                const { point } = await coreApiClient.call("users", "findById", {
                    id: userManager.getUserInfo()._id,
                });
                this.point = point || 0;
            } catch (e) {
                this.initializeError = e;
            } finally {
                this.initializeLoading = false;
            }
        },
        async getLastChargingUnPaid() {
            {
                this.tenantSetting = await coreApiClient.call(
                    "tenants",
                    "getSetting",
                    {
                        id: userManager.getUserInfo().tenant._id,
                    }
                );
            }
            const chargingSessions = await coreApiClient.call("chargingSessions", "findOne", {
                payload: JSON.stringify({
                    limit: -1,
                    filters: [
                        {
                            key: "isPaid",
                            operator: "equal_to",
                            value: false,
                        },
                    ],
                }),
            });
            if (chargingSessions && chargingSessions.point) {
                this.paymentAmount = chargingSessions.point;
                this.addPointBtnOptions = this.makeAddPointBtnOptions(chargingSessions.point, chargingSessions.id);
            }
        },
    },
    async created() {
        this.initializeGetChargingStations();
    },
});
</script>